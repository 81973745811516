import React from "react";
// import Slider from "infinite-react-carousel";
// import Img from "../assets/images/hero_banner_new.png";
import Carousel from 'react-bootstrap/Carousel';
import AnimatedText from 'react-animated-text-content';
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { homeSliderApi } from './../API/homeAPI';
// import Loader from "./Loader";


const HomeBanner = () => {
  const [bannerData, setBannerData] = useState([]);
  const [imageData, setImageData] = useState([]);
  // const [loading, setLoading] = useState(false);
  useEffect(() => { 
    // setLoading(true);
    var config = { "Access-Control-Allow-Origin": "*" }
    homeSliderApi(config, (response) => {
    //success
    // const bannerPic=response.data[0].contentElements;

    // setImageData(response.data[0].contentBackground);
   // console.log("image bacjground", imageData);
    setBannerData(response.data[0].contentElements);
   // console.log("response banner", response.data[0].contentElements);
    
  //   setTimeout(() => {
  //     setLoading(false)
  // }, 3000);
    },(err) => {
        //error
        console.log(err);
    });
      
  }, []);

//   if (loading) {
//     return <Loader />;
// }
    return (
        <section className="section">
       
          <Container fluid className="p-0">
          <Carousel autoPlay={true} interval={6500} controls={false} pause={false} className="bics-home-slider" fade>
          {bannerData.map((item, index) => (
  <Carousel.Item key={index}>
    <img
      className="d-block w-100"
      src={process.env.REACT_APP_BASE_URL + item.ElementBackground}
      alt="First slide"
    />
    <Carousel.Caption>
      
      <AnimatedText
  type="chars" // animate words or chars
  animation={{
    x: '200px',
    y: '-20px',
    scale: 1.1,
    ease: 'ease-in-out',
  }}
  animationType="lights"
  interval={0.1}
  duration={0.8}
  tag="p"
  className="animated-heading"
  includeWhiteSpaces
  threshold={0.2}
  rootMargin="20%"
>
{item.ElementTitle}
</AnimatedText>

<AnimatedText
  type="words" // animate words or chars
  animation={{
    x: '200px',
    y: '-20px',
    scale: 1.1,
    ease: 'ease-in-out',
  }}
  animationType="blocks"
  interval={0.06}
  duration={0.8}
  tag="p"
  className="animated-paragraph"
  includeWhiteSpaces
  threshold={0.1}
  rootMargin="20%"
>
{item.ElementDescription}
</AnimatedText>

    </Carousel.Caption>
  </Carousel.Item>  
))}
      </Carousel>
      </Container>
        </section>
    )
}

export default HomeBanner;