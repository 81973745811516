import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/images/icons/footer_bulletin_arrow_icn.png";

const ServiceFooter = () => {
  return (
    <div>
      <Row>
        <Col xs={12}>
          <h5 className="text-left footertitle footer_text_color">Services</h5>
          <Row className="footer_mob_pad">
            <Col xs={12}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/salesforce">Salesforce</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="footer_mob_pad">
            <Col xs={12}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/marketingcloud">Marketing Solutions</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="footer_mob_pad">
            <Col xs={12}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/digital">Digital Solutions</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="footer_mob_pad">
            <Col xs={12}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/smart">Smart Solutions</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="footer_mob_pad">
            <Col xs={12}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/futuristic">Futuristic Solutions</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceFooter;
