import React,  { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Odometer from "react-odometerjs";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { earnValueApi } from "../API/homeAPI";
import {motion} from "framer-motion";
import VisibilitySensor from "react-visibility-sensor"; 
import clientImg from '../assets/images/client&partner_web_img.png';

const ValueClient = () => {

  const [odometerValue, setOdometerValue] = useState([]);
  // const valueArray = [100,5,97,300];
  const symbolLoader = [ "+","+","%","+" ];
  // const earnCaption = ["Clients","Regions","Conversion Ratio","Projects Delivered"];
  const [valueData, setValueData] = useState([]);
  const [valueContent, setValueContent] = useState([]);
  const [valueMetric, setValueMetric] = useState([]);
  const [view, setView] = useState(false);
  const [valueTitle, setValueTitle] = useState([]);
  const metricNumeric = [];
  const metricTitle = [];

  
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const metricData = () => {
    setOdometerValue(metricNumeric);
    setValueTitle(metricTitle);
  }

  const businessTextAnimate={
    offscreen:{y:100, opacity:0},
    onscreen:{
      y:0,
      opacity:1,
      transition:{
      type:"spring",
      ease: "easeInOut",
      bounce:0.4,
      duration:3,
      
      }
    }
  }

  useEffect(() => {   
    
    // let loopRun = true;
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".bics-header"),
      {
      y:20,

		duration: 1.5,
      },
      {
      y:-20,
      opacity:1,
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
        scrollTrigger: {
          trigger: element.querySelector(".bics-header-wrapper"),
          scrub: 1,
          start: "top top",
          end: "bottom 80%",
          markers: false
        },
      }
    ); 


    var config = { "Access-Control-Allow-Origin": "*" }
    earnValueApi(config, (response) => {
    //success
    const earnTitle = response.data.map(item => item.contentTitle);
    const earnContent = response.data.map(item => item.contentDescription);
    setValueData(earnTitle);
    setValueContent(earnContent);
    // setValueMetric(response.data[0].contentElements);
    const metricValue = response.data.map(item => item.contentElements);   
  
    const metricBox = metricValue[0];
    setValueMetric(metricBox);   
 

// if(loopRun){
  for(let i=0;i<metricBox.length;i++)
  {
      metricNumeric.push(metricBox[i].ElementDescription);
      metricTitle.push(metricBox[i].ElementTitle);
      // console.log("metricBox[i].", metricBox[i]);
  }
  // let loopRun = false;
// }    
    // console.log("metricNumeric", metricNumeric);
    // setMetricNumber(valueMetric.ElementDescription);
    // console.log("res value da", response.data);
    // console.log("res value metricValue", metricBox.length);

 
    },(err) => {
        //error
        console.log(err);
    });

    setTimeout(() => {
      metricData();
    }, 1500);
    
  }, []);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setView(true);
    }
  };
  
  //console.log("res value",valueMetric);
  

    // useEffect(() => {
    //     $('.count').each(function () {
    //         $(this).prop('Counter', -1).animate({
    //             Counter: $(this).text()
    //         }, {
    //             duration: 5000,
    //             easing: 'swing',
    //             step: function (now) {
    //                 $(this).text(Math.ceil(now));
    //             }
    //         });
    //     });
    
    //   }, []);
    const duration = 600;
    return (
      <div className="bics-value-wrapper py-md-5 py-3 py-lg-5 py-xl-6 py-sm-3">
        <Container>
          <Row className="mx-0">
            <div className="bics-header-wrapper" ref={ref}>
              <div className="bics-inner-wrapper pb-md-4 pb-lg-4 pb-sm-2 pb-2 pb-xl-4">
                <h1 className="bics-header">{valueData}</h1>

                <p className="bics-content">{valueContent}</p>
              </div>
            </div>
            <motion.div className="metric-value-container"
                initial={"offscreen"} 
                whileInView={"onscreen"}
                viewport={{once:false, amount:0.1}}
                transition={{staggerChildren:0.5}}
            >
            <Row>
              <Col className="flex-box-1">
              <div className="bics-metric-box-sec">
                <motion.div className="bics-metric-box"  variants={businessTextAnimate}>
                  <div className="bics-metric-inner">
                    <div className="bics-metric-circle">
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        offset={8}
                        delayedCall
                      >
                        <Odometer
                          duration={duration}
                          // value={odometerValue[index]}
                          value={view && odometerValue[0]}
                          // format="(.ddd),dd"
                          //animation="count"
                          format="dd"
                        />
                      </VisibilitySensor>
                    </div>
                    <span className="bics-metrix-sup">
                      <sup>{symbolLoader[0]}</sup>
                    </span>
                  </div>
                  <div className="bics-metric-caption pt-3">
                    <p>{valueTitle[0]}</p>
                  </div>
                </motion.div>

                <motion.div className="bics-metric-box" variants={businessTextAnimate}>
                  <div className="bics-metric-inner">
                    <div className="bics-metric-circle">
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        offset={8}
                        delayedCall
                        scrollDelay={350}
                      >
                        <Odometer
                          duration={duration}
                          // value={odometerValue[index]}
                          value={view && odometerValue[1]}
                          // format="(.ddd),dd"
                          //animation="count"
                          format="dd"
                        />
                      </VisibilitySensor>
                    </div>
                    <span className="bics-metrix-sup">
                      <sup>{symbolLoader[1]}</sup>
                    </span>
                  </div>
                  <div className="bics-metric-caption pt-3">
                    <p>{valueTitle[1]}</p>
                  </div>
                </motion.div>
                </div>
              </Col>
              <Col md="8" lg="8" sm="12" xs="12" xl="8" className="flex-box-2">
                <motion.div className="client-banner">
                <img src={clientImg} alt="client-img" className="w-100"/>
                </motion.div>
                </Col>
              <Col className="flex-box-3">
              <div className="bics-metric-box-sec">
                <motion.div className="bics-metric-box" variants={businessTextAnimate}>
                  <div className="bics-metric-inner">
                    <div className="bics-metric-circle">
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        offset={8}
                        delayedCall
                      >
                        <Odometer
                          duration={duration}
                          // value={odometerValue[index]}
                          value={view && odometerValue[2]}
                          // format="(.ddd),dd"
                          //animation="count"
                          format="dd"
                        />
                      </VisibilitySensor>
                    </div>
                    <span className="bics-metrix-sup">
                      <sup>{symbolLoader[2]}</sup>
                    </span>
                  </div>
                  <div className="bics-metric-caption pt-3">
                    <p>{valueTitle[2]}</p>
                  </div>
                </motion.div>

                <motion.div className="bics-metric-box"  variants={businessTextAnimate}>
                  <div className="bics-metric-inner">
                    <div className="bics-metric-circle">
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        offset={8}
                        delayedCall
                      >
                        <Odometer
                          duration={duration}
                          // value={odometerValue[index]}
                          value={view && odometerValue[3]}
                          // format="(.ddd),dd"
                          //animation="count"
                          format="dd"
                        />
                      </VisibilitySensor>
                    </div>
                    <span className="bics-metrix-sup">
                      <sup>{symbolLoader[3]}</sup>
                    </span>
                  </div>
                  <div className="bics-metric-caption pt-3">
                    <p>{valueTitle[3]}</p>
                  </div>
                </motion.div>

                </div>
              </Col>
            </Row>
            </motion.div>
          </Row>
        </Container>
      </div>
    );
}

export default ValueClient;