import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PrivacyContent = () => {
  return (
    <div className="bics-privacy-container">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center py-3" data-shadow="BICS GLOBAL Privacy Policy">BICS GLOBAL Privacy Policy</h1>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
            <div className="media feature">
              <div className="media-body">
                <h3 className="privacy-heading">I. INTRODUCTION</h3>
                <p>
                  This Privacy Policy explains what information BICS GLOBAL and
                  its related entities (“BICS GLOBAL”) collect about you and
                  why, what we do with that information, how we share it, and
                  how we handle the content you place in our products and
                  services. It also explains the choices available to you
                  regarding our use of your personal information and how you can
                  access and update this information.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
            <div className="media feature">
              <div className="media-body">
                <h3 className="privacy-heading">II. SCOPE OF PRIVACY POLICY</h3>
                <p>
                  <strong>(a) </strong> This Privacy Policy applies to the
                  information that we obtain through your use of “BICS GLOBAL
                  Services” via a “Device” or when you otherwise interact with
                  BICS GLOBAL
                </p>
                <p>
                  <span className="color_full">
                    “BICS GLOBAL Services” include our:
                  </span>
                </p>
                <ul className="inner_list bold-list">
                  <li>Websites</li>
                  <li>SaaS Products</li>
                  <li>IOT Products</li>
                  <li>Downloadable Products</li>
                </ul>
                <p>
                  <span className="color_full">But does not include:</span>
                </p>
                <ul className="inner_list">
                  <li>
                    BICS GLOBAL products or services for which separate privacy
                    policy is provided.
                  </li>
                  <li>
                    Third Party Products. These are third party products or
                    services that you may choose to integrate with BICS GLOBAL
                    product or services, such as third-party Add-Ons. You should
                    always review the policies of third party products and
                    services to make sure you are comfortable with the ways in
                    which they collect and use your information.
                  </li>
                </ul>
                <p>
                  <span className="color_full">But does not include:</span>
                </p>
                <ul className="inner_list">
                  <li>
                    BICS GLOBAL products or services for which separate privacy
                    policy is provided.
                  </li>
                  <li>
                    Third Party Products. These are third party products or
                    services that you may choose to integrate with BICS GLOBAL
                    product or services, such as third-party Add-Ons. You should
                    always review the policies of third party products and
                    services to make sure you are comfortable with the ways in
                    which they collect and use your information.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
            <div className="media feature">
              <div className="media-body">
                <h3 className="privacy-heading">III. DEFINITIONS</h3>
                <ul className="inner_list">
                  <li>
                    Add-On: any amount of code, resources and configuration
                    files that can be used with a BICS GLOBAL product to add new
                    functionality or to change the behavior of that product’s
                    existing features.
                  </li>
                  <li>
                    Content: any information or data that you upload, submit,
                    post, create, transmit, store or display in a BICS GLOBAL
                    Service.
                  </li>
                  <li>
                    Downloadable Products: BICS GLOBAL’s downloadable software
                    products and mobile applications, including Add-Ons created
                    by BICS GLOBAL that is installed by customers on an
                    infrastructure of their choice. Downloadable Products do not
                    include Add-Ons created by third parties, even when they are
                    accessed through the BICS GLOBAL website.
                  </li>
                  <li>
                    Information: All the different forms of data, content, and
                    information collected by us as described in this Privacy
                    Policy.
                  </li>
                  <li>
                    Personal Information: Information that may be used to
                    readily identify or contact you as an individual person,
                    such as name, address, email address, or phone number.
                    Personal Information does not include information that has
                    been anonymized such that it does not allow for the ready
                    identification of specific individuals.
                  </li>
                  <li>
                    SaaS Products: BICS GLOBAL’s “Cloud” hosted solutions, as
                    well as other BICS GLOBAL, hosted solutions that display a
                    link to this Privacy Policy. For the avoidance of doubt, if
                    a BICS GLOBAL hosted solution displays a link to a different
                    privacy policy, then that other privacy policy shall apply.
                  </li>
                  <li>
                    Websites: BICS GLOBAL’s websites, including but not limited
                    to https://BICS GLOBAL.com and any related websites,
                    sub-domains, and pages.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div className="media feature">
<div className="media-body">
<h3 className="privacy-heading">IV. CHANGES TO OUR PRIVACY POLICY</h3>
<p>We may change this Privacy Policy from time to time. If we make any changes, we will notify you by revising the “Effective Starting” date at the top of this Privacy Policy. If we make any material changes, we will provide you with additional notice (such as by adding a notice on the BICS GLOBAL Services homepages, login screens, or by sending you an email notification). We encourage you to review our Privacy Policy whenever you use BICS GLOBAL Services to stay informed about our information practices and the ways you can help protect your privacy.</p>
<p>If you disagree with any changes to this Privacy Policy, you will need to stop using BICS GLOBAL Services and deactivate your account(s), as outlined below.</p>
</div>
</div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div className="media feature">
              <div className="media-body">
              <h3 className="privacy-heading">V. INFORMATION YOU PROVIDE TO US</h3>
              <p>We collect the following information:</p>
                 <span className="color_full"><strong>(a) </strong> Account and Profile Information:</span><br />
              <p>We collect information about you and your company as you register for an account, create or modify your profile, make purchases through, use, access, or interact with the BICS GLOBAL Services (including but not limited to when you upload, download, collaborate on or share Content). Information we collect includes</p>
                  <ul className="inner_list">
                    <li>Contact information such as name, email address, mailing address, and phone number</li>
                    <li>Billing information such as credit card details and billing address</li>
                    <li>Profile information such as a username, profile photo, and job title</li>
                    <li>Preferences information such as notification and marketing preferences</li>
                  </ul>
                
                
              <p>You may provide this information directly when you enter it in BICS GLOBAL Services.</p>
              <p>In some cases another user (such as a system administrator) may create an account on your behalf and may provide your information, including Personal Information (most commonly when your company requests that you use our products). We collect Information under the direction of our customers and often have no direct relationship with the individuals whose personal data we process. If you are an employee of one of our customers and would no longer like us to process your information, please contact your employer. If you are providing information (including Personal Information) about someone else, you must have the authority to act for them and to consent to the collection and use of their Personal Information as described in this Privacy Policy.</p>
                  <ul className="inner_list bullet_remove">
                      <li><span className="color_full">(b) Content:</span><br />
                      We collect and store Content that you create, input, submit, post, upload, transmit, store or display in the process of using our SaaS Products or Websites. Such Content includes any Personal Information or other sensitive information that you choose to include (“incidentally-collected Personal Information”).</li>
                      <li><span className="color_full">(c) Other submissions:</span><br />
                  We collect other data that you submit to our Websites or as you participate in any interactive features of the BICS GLOBAL Services, participate in a survey, contest, promotion, sweepstakes, activity or event, apply for a job, request customer support, communicate with third-party parts social media sites or otherwise communicate with us. For example, information regarding a problem you are experiencing with a BICS GLOBAL product could be submitted to our Support Services or posted in our public forums.</li>
                  </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div className="media feature">
                <div className="media-body">
                  <h3 className="privacy-heading">VI. INFORMATION WE COLLECT FROM YOUR USE OF BICS GLOBAL SERVICES</h3>
                <ul className="bullet_remove">
                  <li><span className="color_full sub_head_privacy">(a) Web Logs:</span><br />
                As is true with most websites and services delivered over the Internet, we gather certain information and store it in log files when you interact with our Websites and SaaS Products. This information includes internet protocol (IP) addresses as well as browser type, internet service provider, URLs of referring/exit pages, operating system, date/time stamp, the information you search for, locale and language preferences, identification numbers associated with your Devices, your mobile carrier, and system configuration information. In the case of our SaaS Product, the URLs you accessed (and therefore included in our log files) include usernames as well as elements of Content (such as BICS GLOBAL project names, project keys, status names, and page titles and space names) as necessary for the SaaS Product to perform the requested operations. Occasionally, we connect Personal Information to information gathered in our log files as necessary to improve BICS GLOBAL Services for individual customers. In such a case, we would treat the combined Information in accordance with this privacy policy.<br />
                <span className="color_full sub_head_privacy">(b) Analytics Information from Website and SaaS Products:</span><br />
                We collect analytics information when you use our Websites and SaaS Products to help us improve our products and services. In the SaaS Products, this analytics information consists of the feature and function of the BICS GLOBAL Service being used, the associated license identifier (SEN) and domain name, the username and IP address of the individual who is using the feature or function (which will include Personal Information if the Personal Information was incorporated into the username), the sizes and original filenames of attachments, and additional information required to detail the operation of the function and which parts of the BICS GLOBAL Services are being affected. For example:<p></p>
                <ul className="inner_list">
                <li>In SenseQ, analytics information that we collect when a user creates a project includes the system-generated numeric identifier of the project, the project key input by the user, the name of the template used (if any), and the name of the project. Similarly, analytics information that we collect when an issue is edited includes the system-assigned numeric issue id, the issue key (which consists of the project key and a system-generated sequence number), whether the edit is made by a user or automatically generated by the system, and whether email notifications are to be sent.</li>
                </ul>
                <p>As shown in the example above, the analytics information we collect includes elements of Content related to the function the user is performing. As such, the analytics information we collect may include Personal Information or sensitive business information that the user has included in Content that the user chose to upload, submit, post, create, transmit, store or display in a BICS GLOBAL Service.<br />
                <div className="privacy_alert">As of the date this policy went into effect, we use Google Analytics as an analytics provider. To learn more about the privacy policy of Google Analytics, refer to Google’s Policies and Principles. Use the Google Analytics Opt-out Browser Add-on to prevent analytics information from being sent to Google Analytics.<br />
                </div></p>
                <p><span className="color_full sub_head_privacy">(c) Analytics Information Derived from Content:</span><br />
                Analytics information also consists of data we collect as a result of running queries against Content across our user base for the purposes of generating Usage Data. “Usage Data” is aggregated data about a group or category of services, features or users that does not contain Personal Information. For example, we may query Content to determine the most common types of workflows that users use (e.g. what percentage of all instances use MQTT protocol?) by searching for the most common workflow names, or we may query Content to determine the most popular job titles for SenseQ users in order to better understand the composition of our user base.</p></li>
                </ul>
                <p>Though we may happen upon sensitive or Personal Information as we compile Usage Data from Content across user instances, this is a byproduct of our efforts to understand broader patterns and trends. It is not a concerted effort by us to examine the Content of any particular customer.</p>
                <ul className="bullet_remove">
                <li><span className="color_full sub_head_privacy">(d) Cookies and Other Tracking Technologies:</span><br />
                BICS GLOBAL uses various technologies to collect information, such as cookies. Cookies are small data files stored on your hard drive or in device memory. We use cookies to improve and customize BICS GLOBAL Services and your experience; to allow you to access and use the Websites or SaaS Products without re-entering your username or password; and to count visits and understand which areas and features of the Websites and SaaS Products are most popular. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from websites you visit. If you do not accept cookies, however, you may not be able to use all aspects of our Websites or SaaS Products.</li>
                </ul>
                <p>BICS GLOBAL and our third party partners also use javascript, e-tags, “flash cookies”, and HTML5 local storage to collect information about your online activities over time and across different websites or online services. Many browsers include their own management tools for removing HTML5 local storage objects. To manage “flash cookies” please click here.</p>
                </div>
                </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div className="media feature">
                <div className="media-body">
                  <h3 className="privacy-heading">VII. INFORMATION WE COLLECT FROM OTHER SOURCES</h3>
                      <p><div className="color_full py-2">Information from third party services:</div>
                      We also obtain information from third parties and combine that with Information we collect through BICS GLOBAL Services. For example, we may have access to certain information from a third party social media or authentication service if you log into BICS GLOBAL Services through the service or otherwise provide us with access to Information from the service. Any access that we may have to such Information from a third party social or authentication service is in accordance with the authorization procedures determined by that service. By authorizing us to connect with a third party service, you authorize us to access and store your name, email address(es), current city, profile picture URL, and other information that the third party service makes available to us, and to use and disclose it in accordance with this Privacy Policy. You should check your privacy settings on these third party services to understand and change the information sent to us through these services.</p>
                </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div className="media feature">
                <div className="media-body">
                  <h3 className="privacy-heading">VIII. HOW WE USE INFORMATION WE COLLECT</h3>
                <ul className="bullet_remove">
                <li><div className="color_full pb-2">(a) General Uses:</div>
                We use the Information we collect about you (including Personal Information to the extent applicable) for a variety of purposes, including to:<p></p>
                <ul className="inner_list">
                <li>Provide, operate, maintain, improve, and promote BICS GLOBAL Services;</li>
                <li>Enable you to access and use BICS GLOBAL Services, including uploading, downloading, collaborating on and sharing Content;</li>
                <li>Process and complete transactions, and send you related information, including purchase confirmations and invoices;</li>
                <li>Send transactional messages, including responding to your comments, questions, and requests; providing customer service and support; and sending you technical notices, updates, security alerts, and support and administrative messages;</li>
                <li>Send promotional communications, such as providing you with information about services, features, surveys, newsletters, offers, promotions, contests, events and sending updates about your team and chat rooms; and providing other news or information about us and our select partners. You have the ability to opt out of receiving any of these communications as described below under “Your Choices”;</li>
                <li>Process and deliver contest or sweepstakes entries and rewards;</li>
                <li>Monitor and analyze trends, usage, and activities in connection with BICS GLOBAL Services and for marketing or advertising purposes;</li>
                <li>Investigate and prevent fraudulent transactions, unauthorized access to BICS GLOBAL Services, and other illegal activities;</li>
                <li>Personalize BICS GLOBAL Services, including by providing content, features, or advertisements that match your interests and preferences;</li>
                <li>Enable you to communicate, collaborate, and share Content with users you designate; and</li>
                <li>For other purposes about which we obtain your consent.</li>
                </ul>
                </li>
                </ul>
                <div className="inner_list pb-2"><strong className="color_black">(b) </strong>Notwithstanding the foregoing, we will not use Personal Information appearing in our Analytics Logs or Web Logs for any purpose. The use of Information collected through our BICS GLOBAL Services shall be limited to the purposes disclosed in this policy.</div>
                <div className="inner_list pb-4"><div className="color_full pb-2">(c) Compiling aggregate analytics information:</div>
                Because our SaaS Products are some of the most configurable in the market, we make extensive use of analytics information (including log and configuration data) to understand how our products are being configured and used, how they can be improved for the benefit of all of our users, and to develop new products and services. As such we generate Usage Data (as defined above) from the web logs and analytics logs described above, including the Content elements captured in such logs, as well as from the Content stored in the Websites and SaaS Products.</div>
                </div>
                </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div className="media feature">
              <div className="media-body">
                <h3 className="privacy-heading">IX. INFORMATION SHARING AND DISCLOSURE</h3>
                <p>We will not share or disclose any of your Personal Information or Content with third parties except as described in this policy. We do not sell your Personal Information or Content.</p>
              <ul className="bullet_remove">
                <li><div className="color_full py-2">(a) Your Use:</div>
              When you use BICS GLOBAL Services, Content you provide will be displayed back to you. Certain features of BICS GLOBAL Services allow you or your administrator to make some of your Content public, in which case it will become readily accessible to anyone. We urge you to consider the sensitivity of any data you input into BICS GLOBAL Services.<br/>
                  <div className="color_full py-2">(b) Collaboration:</div>
                  As a natural result of using BICS GLOBAL Services, you may create Content and grant permission to other BICS GLOBAL users to access it for the purposes of collaboration. Some of the collaboration features of BICS GLOBAL Services display your profile information, including Personal Information included in your profile, to users with whom you have shared your Content. Where this information is sensitive, we urge you to use the various security and privacy features of the BICS GLOBAL Services to limit those who can access such information. Your sharing settings may make any Information, including some Personal Information, that you submit to the BICS GLOBAL Services visible to the public unless submitted to a restricted area.<br/>
                  <div className="color_full py-2">(c) Access by your system administrator:</div>
                  You should be aware that the administrator of your instance of BICS GLOBAL Services may be able to:
              <ul className="inner_list">
                  <li>Access information in and about your BICS GLOBAL Services account;</li>
                  <li>Access communications history, including file attachments, for your BICS GLOBAL Services account;</li>
                  <li>Disclose, restrict, or access information that you have provided or that is made available to you when using your BICS GLOBAL Services account, including your Content; and</li>
                  <li>Control how your BICS GLOBAL Services account may be accessed or deleted.</li>
              </ul>
                  <p><div className="color_full py-2" >(d) Service Providers, Business Partners and Others:</div>
                      We work with third party service providers to provide website, application development, hosting, maintenance, back-up, storage, virtual infrastructure, payment processing, analysis and other services for us. These service providers may have access to or process your Information for the purpose of providing those services for us. Some of our pages utilize white-labeling techniques to serve content from our service providers while providing the look and feel of our site. Please be aware that you are providing your Information to these third parties acting on behalf of BICS GLOBAL.</p>
                      <p><div className="color_full py-2" >(e) Third Party Add-Ons:</div>
                      You may choose to make use of third party Add-Ons in conjunction with BICS GLOBAL Services. Third party Add-Ons are software written by third parties to which you grant access privileges to your Content (which may include your Personal Information). When access is granted, your Content is shared with the third party. Third party Add-On policies and procedures are not controlled by BICS GLOBAL even though the third party Add-On may be available through BICS GLOBAL Services. Third parties who have been granted access to your Content through Add-Ons could use this data to contact you and market services to you, and could share your data with other third parties. This Privacy Policy does not cover the collection or use of your data by third party Add-Ons, and we urge you to consider the privacy policies governing third party Add-Ons. If you object to your Personal Information being shared with these third parties, please uninstall the Add-On (in the event installed from the BICS GLOBAL Marketplace) or terminate your agreement with the third party Add-On provider (in the event you have purchased a direct integration).</p>
                      <p><div className="color_full py-2" >(f) Links to Third Party Sites:</div>
                      The BICS GLOBAL Services may include links to other websites whose privacy practices may differ from ours. If you submit Personal Information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.</p>
                      <p><div className="color_full py-2" >(g) Social Media Widgets:</div>
                      The BICS GLOBAL Services may contain social media features, such as the Twitter “tweet” button. These features may collect your IP address, which page you are visiting on the BICS GLOBAL Services, and may set a cookie to enable the feature to function properly. Social media features and Widgets are either hosted by a third party or hosted directly on our BICS GLOBAL Services. Your interactions with these features are governed by the privacy policy of the company providing it.</p>
                      <p><div className="color_full py-2" >(h) Testimonials:</div>
                      We may display personal testimonials of satisfied customers on the BICS GLOBAL Services. With your consent, we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us using the information below.</p>
                      <p><div className="color_full py-2" >(i) Compliance with Laws and Law Enforcement Requests; Protection of Our Rights:</div>
                      We may disclose your Information (including your Personal Information) to a third party if (a) we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request, (b) to enforce our agreements, policies and terms of service, (c) to protect the security or integrity of BICS GLOBAL’s products and services, (d) to protect BICS GLOBAL, our customers or the public from harm or illegal activities, or (e) to respond to an emergency which we believe in the good faith requires us to disclose information to assist in preventing the death or serious bodily injury of any person.</p>
                      <p><div className="color_full py-2" >(j) Business Transfers:</div>
                      We may share or transfer your Information (including your Personal Information) in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. You will be notified via email and/or a prominent notice on the BICS GLOBAL Services of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.</p>
                      <p><div className="color_full py-2" >(k) Aggregated or Anonymized Data:</div>
                      We may also share aggregated or anonymized information<br/>
                      that does not directly identify you with the third parties described above.</p>
                      <p><div className="color_full py-2" >(l) With Your Consent:</div>
                      We will share your Personal Information with third parties when we have your consent to do so.</p>
                      </li>
              </ul>
              </div>
              </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div  className="col-md-12 col-sm-12 col-xs-12 indus-subpage-content">
            <div  className="media feature pb-3">
              <div  className="media-body">
                  <h3  className="privacy-heading">X. INFORMATION WE DO NOT SHARE</h3>
                    <p>We do not share Personal Information about you with third parties for their marketing purposes (including direct marketing purposes) without your permission.</p>
              </div>
            </div>
          </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div  className="media feature">
              <div  className="media-body">
                  <h3  className="privacy-heading">XI. DATA STORAGE, TRANSFER AND SECURITY</h3>
                <ul className="bullet_remove">
                    <li><strong  className="color_black">(a) </strong> BICS GLOBAL hosts data with hosting service providers in numerous countries including the <span  className="privacy_alert">United States, Brazil, and Germany</span>. The servers on which Personal Information is stored are kept in a controlled environment. While we take reasonable efforts to guard your Personal Information, no security system is impenetrable and due to the inherent nature of the Internet as an open global communications vehicle, we cannot guarantee that information, during transmission through the Internet or while stored on our systems or otherwise in our care, will be absolutely safe from intrusion by others, such as hackers. In addition, we cannot guarantee that any incidentally-collected Personal Information you choose to store in Websites or SaaS Products are maintained at levels of protection to meet specific needs or obligations you may have relating to that information. 
                        <div className="py-2"><strong  className="color_black">(b) </strong>Where data is transferred over the Internet as part of a Website or SaaS Product, the data is encrypted using industry standard SSL (HTTPS).</div>
                                  </li>
                  </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div  className="media feature">
              <div  className="media-body">
                <h3  className="privacy-heading">XII. YOUR CHOICES</h3>
                      <ul className="bullet_remove">
                          <li><strong  className="color_black">(a) </strong>You may opt out of receiving promotional communications from BICS GLOBAL by using the unsubscribe link within each email or emailing us to have your contact information removed from our promotional email list or registration database. Although opt-out requests are usually processed immediately, please allow ten (10) business days for a removal request to be processed. Even after you opt out from receiving promotional messages from us, you will continue to receive transactional messages from us regarding BICS GLOBAL’s Services. You can opt-out of some notification messages in your account settings. </li>
                          <li><strong  className="color_black">(b) </strong>You may be able to opt out of receiving personalized advertisements from companies who are members of the Network Advertising Initiative or who subscribe to the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising. For more information about this practice and to understand your options, please visit: http://www.aboutads.info and http://www.networkadvertising.org/choices/.</li>
                      </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div  className="media feature">
              <div  className="media-body">
                <h3  className="privacy-heading">XIII. ACCESSING AND UPDATING YOUR INFORMATION</h3>
                <ul className="bullet_remove updat_info">
                    <li><strong  className="color_black">(a) </strong>You may often correct, update, amend, or remove your Personal Information in your account settings or by directing your query to your account administrator. You may also contact Support Services, or contact us by postal mail using the address listed below. We will respond to your request for access within 30 days.</li>
                    <li><strong  className="color_black">(b) </strong>You can often remove Content using editing tools associated with that Content. In some cases, you may need to contact your administrator to request they remove the Content. You can contact us to request removal of Personal Information by emailing <span className="privacy_alert">info@bicsglobal.com.
                    </span></li>
                    <li><strong  className="color_black">(c) </strong>You or your administrator may be able to deactivate your BICS GLOBAL Services account. If you can deactivate your own account, you can most often do so in your account settings. Otherwise, please contact your administrator. To deactivate an organization account, please contact Support Services. To deactivate an account made for you without authorization, please contact us at the contact information below.</li>
                    <li><strong  className="color_black">(d) </strong>We will retain your account information for as long as your account is active or as reasonably useful for commercial purposes or as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. If your account is managed by an administrator, that account administrator may have control with regards to how your account information is retained and deleted.</li>
                  </ul>
                </div>
              </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div  className="media feature">
            <div  className="media-body">
              <h3  className="privacy-heading">XIV. OUR POLICY TOWARDS CHILDREN</h3>
                <p>BICS GLOBAL Services are not directed to individuals under 13. We do not knowingly collect Personal Information from children under 13. If we become aware that a child under 13 has provided us with Personal Information, we will take steps to delete such information. If you become aware that a child has provided us with Personal Information, please contact our Support Services.</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div  className="media feature">
              <div  className="media-body">
                <h3  className="privacy-heading">XV. INTERNATIONAL USERS; PRIVACY SHIELD RELATED COMPLAINTS</h3>
                  <ul className="updat_info bullet_remove pt-2">
                      <li><strong  className="color_black">(a) </strong>If you are visiting from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your Personal Information to Germany and the United States to us. By providing your Personal Information, you consent to any transfer and processing in accordance with this Policy.</li>
                      <li> 
                      <strong  className="color_black">(b) </strong>We encourage you to contact us if you have any question or concerns about this Policy using the contact information below. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at https://feedback-form.truste.com/watchdog/request. BICS GLOBAL has also chosen to cooperate with EU data protection authorities (DPAs) and comply with the information and advice provided to it by an informal panel of DPAs in relation to such unresolved complaints (as further described in the Privacy Shield Principles).</li>
                      <li>
                      <strong  className="color_black">(c) </strong>Please contact us to be directed to the relevant DPA contacts. As further explained in the Privacy Shield Principles, a binding arbitration option will also be made available to you in order to address residual complaints not resolved by any other means. BICS GLOBAL is subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission (FTC).</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} sm={12}>
          <div class="media feature mb-5">
            <div class="media-body">
              <h5 class="media-heading  pt-2 pb-4">CONTACT US</h5>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyContent;
