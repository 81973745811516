import React from "react";
import PrivacyContent from "../../components/PrivacyContent";

const PrivacyPolicy = () => {

    return(
        <div>
            <PrivacyContent />
        </div>
    )
}

export default PrivacyPolicy;