import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import IndustryFooter from './IndustryFooter'
import Reachus from './Reachus'
import ServiceFooter from './ServiceFooter'
import SocialFooter from './SocialFooter';
import { Link } from 'react-router-dom';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy'

const Footer = () => {
  return (
    <div className='footer_bg'>
      <Container>
        <Row className='pt-5 pb-5'>
        <Col xl={3} md={6} xs={12}>
            <Reachus />
        </Col>
        <Col xl={3} md={6} xs={12}>
        <ServiceFooter />
        </Col>
        <Col xl={3} md={6} xs={12}>
        <IndustryFooter />
        </Col>
        <Col xl={3} md={6} xs={12}>
        <SocialFooter />
        </Col>
        </Row>
      </Container>
      <div className='copyright_block'>
        <Container>
          <Row>
          <Col xl={6} md={6} xs={12} className="text-lg-left">
            Copyright © 2024 BICS GLOBAL. All Rights Reserved
          </Col>
          <Col xl={6} md={6} xs={12} className="text-lg-right">
           <span className='px-3 text-underline'>
            <Link to="/privacypolicy"
            className='foot_policy'>Privacy Policy</Link></span> 
           <span className='px-3 text-underline'>
            <Link to="/cookiespolicy" 
             className='foot_policy'>Cookies Policy</Link></span>
          </Col>
          </Row></Container></div>
    </div>
  )
}

export default Footer
