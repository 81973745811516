import { React } from 'react';

const Logo = () => {
     
    return (
        <div>
           <svg  
           id="bg_logo" xmlns="http://www.w3.org/2000/svg" width="260" height="64" viewBox="0 0 260 64" >
  <g id="Group_1965" data-name="Group 1965">
    <path id="Path_129" data-name="Path 129" d="M1329.667,998.973l41.105,3.392s3.33-.165,3.082,3.56l.022,8.689h-14.454s-4.26-.083-5.976,1.352-2.932,2.62-2.988,5.048a5.833,5.833,0,0,0-4.26-6.179,29.932,29.932,0,0,0-6.363-.773l-13.944-.111v-12.083S1325.268,998.723,1329.667,998.973Z" transform="translate(-1311.449 -985.151)" fill="#00457a"/>
    <path   
     id="Path_130" data-name="Path 130" d="M1349.968,1023.859a4.984,4.984,0,0,1-2.2,4.139s-1.2,1.489-4.937,1.737l-13.176-.1-.021-12.5,14.857.021a1.194,1.194,0,0,0,1.411-1.215,1.385,1.385,0,0,0-1.432-1.765h-18.488l-.02,26.773s-.159,3.572,5.789,3.083l21.444-2.164,18.016-1.7a2.74,2.74,0,0,0,2.783-3.188l.008-22.636-15.195-.167s-5.021-.041-4.813,4.221l-.041,3.724s-.083,3.559,4.4,3.435l8.465-.083-.041-2.359-6.929.041s-3.085.3-2.947-3.6c0,0,0-1.2.456-1.241h14.151l.125,11.917-12.74.083s-4.337.393-6.553-2.4a9.022,9.022,0,0,1-2.245-6.291" transform="translate(-1311.416 -980.078)" fill="#62aa57"/>
    <path 
   id="Path_131" data-name="Path 131" d="M1331.859,1019.77h10.457s1.556-.323,1.619,1.083-.747,1.975-1.743,1.919-10.333-.017-10.333-.017Z" transform="translate(-1309.449 -978.224)" fill="#62aa57"/>
    <path
     id="Path_132" data-name="Path 132" d="M1325.749,994.785l-7.709-1.2v6.436h7.8Z" transform="translate(-1314.056 -986.943)" fill="#00457a"/>
    <path id="Path_133" data-name="Path 133" d="M1325.012,993.444V999.9h7.47v-4.3Z" transform="translate(-1311.732 -986.989)" fill="#00457a"/>
    <path id="Path_134" data-name="Path 134" d="M1327.377,994.384l-4.98-1.375v-4.282l4.98.7Z" transform="translate(-1312.604 -988.561)" fill="#00457a"/>
    <path id="Path_135" data-name="Path 135" d="M1315.052,992.41V988.6l4.316.828v3.642Z" transform="translate(-1315.052 -988.603)" fill="#00457a"/>
  </g>
  <g id="Group_65" data-name="Group 65" transform="translate(78.381 21.179)">
    <path id="Path_136" data-name="Path 136" d="M1373.838,1004.71h5.9q4.032,0,5.855,1.145a3.98,3.98,0,0,1,1.823,3.64,4.574,4.574,0,0,1-.8,2.78,3.189,3.189,0,0,1-2.121,1.307v.129a4.174,4.174,0,0,1,2.6,1.5,4.874,4.874,0,0,1,.8,2.923,4.809,4.809,0,0,1-1.875,4.033,8.112,8.112,0,0,1-5.089,1.449h-7.093Zm4.02,7.488h2.333a4.279,4.279,0,0,0,2.367-.5,1.88,1.88,0,0,0,.733-1.668,1.667,1.667,0,0,0-.8-1.559,5.141,5.141,0,0,0-2.523-.472h-2.113Zm0,3.181v4.927h2.619a3.9,3.9,0,0,0,2.452-.633,2.339,2.339,0,0,0,.791-1.94q0-2.354-3.372-2.353Z" transform="translate(-1373.838 -1004.413)" fill="#00457a"/>
    <path id="Path_137" data-name="Path 137" d="M1387.221,1023.617V1004.71h4.02v18.907Z" transform="translate(-1369.377 -1004.413)" fill="#00457a"/>
    <path id="Path_138" data-name="Path 138" d="M1402.079,1007.843a4.1,4.1,0,0,0-3.515,1.7,7.9,7.9,0,0,0-1.244,4.74q0,6.324,4.759,6.323a15.071,15.071,0,0,0,4.837-.995v3.361a13.464,13.464,0,0,1-5.213.971,7.961,7.961,0,0,1-6.328-2.5q-2.192-2.5-2.192-7.184a11.7,11.7,0,0,1,1.076-5.165,7.769,7.769,0,0,1,3.093-3.4,9.175,9.175,0,0,1,4.727-1.183,12.788,12.788,0,0,1,5.551,1.332l-1.3,3.259a21.669,21.669,0,0,0-2.139-.88A6.426,6.426,0,0,0,1402.079,1007.843Z" transform="translate(-1367.39 -1004.48)" fill="#00457a"/>
    <path id="Path_139" data-name="Path 139" d="M1417.992,1018.434a4.882,4.882,0,0,1-1.848,4.035,8.047,8.047,0,0,1-5.143,1.475,12.1,12.1,0,0,1-5.368-1.139v-3.724a22.126,22.126,0,0,0,3.248,1.2,9.61,9.61,0,0,0,2.432.349,3.47,3.47,0,0,0,2.03-.5,1.728,1.728,0,0,0,.707-1.5,1.649,1.649,0,0,0-.312-.989,3.476,3.476,0,0,0-.913-.835,23.869,23.869,0,0,0-2.457-1.28,11.618,11.618,0,0,1-2.607-1.565,5.853,5.853,0,0,1-1.388-1.745,4.961,4.961,0,0,1-.519-2.328,4.88,4.88,0,0,1,1.705-3.944,7.1,7.1,0,0,1,4.713-1.435,11.137,11.137,0,0,1,2.821.349,19.347,19.347,0,0,1,2.808.983l-1.3,3.116a19.138,19.138,0,0,0-2.509-.867,8.086,8.086,0,0,0-1.952-.245,2.589,2.589,0,0,0-1.751.531,1.745,1.745,0,0,0-.609,1.383,1.7,1.7,0,0,0,.247.925,2.7,2.7,0,0,0,.784.763,24.219,24.219,0,0,0,2.548,1.325,10.14,10.14,0,0,1,3.645,2.541A4.956,4.956,0,0,1,1417.992,1018.434Z" transform="translate(-1363.24 -1004.48)" fill="#00457a"/>
    <path id="Path_140" data-name="Path 140" d="M1430.063,1013.067h7.521v9.8a21.2,21.2,0,0,1-3.444.833,22.4,22.4,0,0,1-3.3.24,8.36,8.36,0,0,1-6.555-2.516q-2.264-2.514-2.264-7.221a9.511,9.511,0,0,1,2.627-7.139,9.988,9.988,0,0,1,7.281-2.56,14.119,14.119,0,0,1,5.628,1.164l-1.335,3.207a9.573,9.573,0,0,0-4.319-1.035,5.337,5.337,0,0,0-4.176,1.745,6.759,6.759,0,0,0-1.569,4.695,7.522,7.522,0,0,0,1.264,4.7,4.379,4.379,0,0,0,3.677,1.623,13.083,13.083,0,0,0,2.555-.257V1016.4h-3.592Z" transform="translate(-1357.777 -1004.48)" fill="#00457a"/>
    <path id="Path_141" data-name="Path 141" d="M1437.078,1023.617V1004.71h4.02v15.6h7.689v3.311Z" transform="translate(-1352.758 -1004.413)" fill="#00457a"/>
    <path id="Path_142" data-name="Path 142" d="M1465.751,1014.212q0,4.694-2.334,7.215a10.138,10.138,0,0,1-13.384,0q-2.334-2.52-2.333-7.241t2.34-7.209a8.77,8.77,0,0,1,6.712-2.489q4.368,0,6.684,2.509T1465.751,1014.212Zm-13.837,0a7.876,7.876,0,0,0,1.205,4.771,4.223,4.223,0,0,0,3.605,1.6q4.812,0,4.811-6.375t-4.784-6.388a4.253,4.253,0,0,0-3.619,1.609A7.844,7.844,0,0,0,1451.914,1014.212Z" transform="translate(-1349.218 -1004.487)" fill="#00457a"/>
    <path id="Path_143" data-name="Path 143" d="M1464.185,1004.71h5.9q4.032,0,5.855,1.145a3.98,3.98,0,0,1,1.823,3.64,4.575,4.575,0,0,1-.8,2.78,3.19,3.19,0,0,1-2.12,1.307v.129a4.175,4.175,0,0,1,2.6,1.5,4.875,4.875,0,0,1,.8,2.923,4.809,4.809,0,0,1-1.875,4.033,8.112,8.112,0,0,1-5.089,1.449h-7.093Zm4.02,7.488h2.333a4.279,4.279,0,0,0,2.367-.5,1.88,1.88,0,0,0,.733-1.668,1.667,1.667,0,0,0-.8-1.559,5.142,5.142,0,0,0-2.523-.472H1468.2Zm0,3.181v4.927h2.619a3.9,3.9,0,0,0,2.452-.633,2.339,2.339,0,0,0,.791-1.94q0-2.354-3.372-2.353Z" transform="translate(-1343.723 -1004.413)" fill="#00457a"/>
    <path id="Path_144" data-name="Path 144" d="M1489.771,1023.636l-1.375-4.5h-6.912l-1.375,4.5h-4.332l6.692-18.984h4.915l6.717,18.984Zm-2.334-7.863q-1.906-6.116-2.147-6.919t-.343-1.267q-.428,1.654-2.452,8.185Z" transform="translate(-1339.858 -1004.432)" fill="#00457a"/>
    <path id="Path_145" data-name="Path 145" d="M1491.311,1023.617V1004.71h4.02v15.6h7.691v3.311Z" transform="translate(-1334.681 -1004.413)" fill="#00457a"/>
  </g>
  <g id="Group_66" data-name="Group 66" transform="translate(86.344 47.343)">
    <path id="Path_146" data-name="Path 146" d="M1385.054,1025.333h-2.144v7.087h-.955v-7.087h-2.145v-.835h5.244Z" transform="translate(-1379.81 -1023.981)" fill="#515353"/>
    <path id="Path_147" data-name="Path 147" d="M1389.006,1031.641a3.625,3.625,0,0,1-1.7.364,2.852,2.852,0,0,1-3.017-3.02,3.231,3.231,0,0,1,.743-2.245,2.457,2.457,0,0,1,1.909-.823,2.3,2.3,0,0,1,1.78.717,2.893,2.893,0,0,1,.66,2.033,3.874,3.874,0,0,1-.059.693h-4.079a1.984,1.984,0,0,0,.672,1.351,2.248,2.248,0,0,0,1.485.471,2.789,2.789,0,0,0,1.6-.412Zm-2.063-4.913a1.538,1.538,0,0,0-1.143.483,2.183,2.183,0,0,0-.555,1.375h3.207v-.235a1.689,1.689,0,0,0-.4-1.181A1.422,1.422,0,0,0,1386.943,1026.728Z" transform="translate(-1378.316 -1023.508)" fill="#515353"/>
    <path id="Path_148" data-name="Path 148" d="M1393.372,1031.743a2.655,2.655,0,0,1-1.391.341,2.912,2.912,0,0,1-2.245-.9,3.117,3.117,0,0,1-.831-2.192,3.149,3.149,0,0,1,.831-2.187,2.912,2.912,0,0,1,2.269-.916,2.791,2.791,0,0,1,1.367.329v.88a2.227,2.227,0,0,0-1.272-.364,2.107,2.107,0,0,0-1.621.653,2.283,2.283,0,0,0-.607,1.615,2.355,2.355,0,0,0,.589,1.605,2.094,2.094,0,0,0,1.639.64,2.269,2.269,0,0,0,1.272-.388Z" transform="translate(-1376.779 -1023.516)" fill="#515353"/>
    <path id="Path_149" data-name="Path 149" d="M1398.047,1032.516h-.931v-3.855a1.4,1.4,0,0,0-.336-1,1.157,1.157,0,0,0-.877-.352,1.574,1.574,0,0,0-1.156.505,1.984,1.984,0,0,0-.495,1.445v3.256h-.919v-8.309h.919v3.385a1.909,1.909,0,0,1,1.885-1.117,1.865,1.865,0,0,1,1.385.529,1.946,1.946,0,0,1,.524,1.433Z" transform="translate(-1375.302 -1024.078)" fill="#515353"/>
    <path id="Path_150" data-name="Path 150" d="M1402.82,1031.949h-.931v-3.855a1.388,1.388,0,0,0-.336-1.005,1.168,1.168,0,0,0-.877-.346,1.545,1.545,0,0,0-1.161.517,2.006,2.006,0,0,0-.489,1.433v3.256h-.919v-5.959h.919v1.035a1.91,1.91,0,0,1,1.887-1.117,1.863,1.863,0,0,1,1.391.529,1.928,1.928,0,0,1,.517,1.411Z" transform="translate(-1373.711 -1023.511)" fill="#515353"/>
    <path id="Path_151" data-name="Path 151" d="M1408.224,1028.988a3.234,3.234,0,0,1-.755,2.2,2.6,2.6,0,0,1-2.073.869,2.55,2.55,0,0,1-2.033-.864,3.212,3.212,0,0,1-.748-2.18,3.253,3.253,0,0,1,.772-2.215,2.535,2.535,0,0,1,2.01-.888,2.593,2.593,0,0,1,2.092.871A3.3,3.3,0,0,1,1408.224,1028.988Zm-4.644.011a2.535,2.535,0,0,0,.5,1.581,1.572,1.572,0,0,0,1.32.652,1.627,1.627,0,0,0,1.379-.64,2.576,2.576,0,0,0,.483-1.593,2.728,2.728,0,0,0-.459-1.627,1.611,1.611,0,0,0-1.4-.641,1.56,1.56,0,0,0-1.332.659A2.642,2.642,0,0,0,1403.58,1029Z" transform="translate(-1372.208 -1023.511)" fill="#515353"/>
    <path id="Path_152" data-name="Path 152" d="M1408.814,1032.516h-.931v-8.309h.931Z" transform="translate(-1370.452 -1024.078)" fill="#515353"/>
    <path id="Path_153" data-name="Path 153" d="M1415.251,1028.988a3.233,3.233,0,0,1-.753,2.2,2.6,2.6,0,0,1-2.075.869,2.549,2.549,0,0,1-2.033-.864,3.212,3.212,0,0,1-.748-2.18,3.253,3.253,0,0,1,.772-2.215,2.536,2.536,0,0,1,2.009-.888,2.593,2.593,0,0,1,2.092.871A3.3,3.3,0,0,1,1415.251,1028.988Zm-4.643.011a2.533,2.533,0,0,0,.495,1.581,1.572,1.572,0,0,0,1.32.652,1.626,1.626,0,0,0,1.379-.64,2.566,2.566,0,0,0,.483-1.593,2.729,2.729,0,0,0-.459-1.627,1.611,1.611,0,0,0-1.4-.641,1.561,1.561,0,0,0-1.332.659A2.643,2.643,0,0,0,1410.609,1029Z" transform="translate(-1369.866 -1023.511)" fill="#515353"/>
    <path id="Path_154" data-name="Path 154" d="M1419.781,1031.467a2.64,2.64,0,0,1-.755,2.052,2.778,2.778,0,0,1-1.945.687,4.279,4.279,0,0,1-1.849-.364v-.928a3.481,3.481,0,0,0,1.768.435,1.916,1.916,0,0,0,1.355-.459,1.865,1.865,0,0,0,.495-1.433v-.671a1.905,1.905,0,0,1-1.756,1.011,2.342,2.342,0,0,1-1.749-.752,2.887,2.887,0,0,1-.725-2.092,3.437,3.437,0,0,1,.672-2.185,2.126,2.126,0,0,1,1.756-.859,1.915,1.915,0,0,1,1.8,1.069v-.987h.932Zm-4.2-2.539a2.135,2.135,0,0,0,.495,1.523,1.505,1.505,0,0,0,1.12.523,1.587,1.587,0,0,0,1.149-.5,2.018,2.018,0,0,0,.5-1.487,2.519,2.519,0,0,0-.483-1.693,1.454,1.454,0,0,0-1.12-.552,1.429,1.429,0,0,0-1.213.605A2.592,2.592,0,0,0,1415.585,1028.928Z" transform="translate(-1368.207 -1023.511)" fill="#515353"/>
    <path id="Path_155" data-name="Path 155" d="M1422.512,1031.775a3.876,3.876,0,0,1-1.091,1.7,2.276,2.276,0,0,1-1.444.493,1.676,1.676,0,0,1-.495-.059v-.833a1.59,1.59,0,0,0,.4.047,1.747,1.747,0,0,0,1.685-1.352l-2.416-5.805h1.048l.779,1.963,1.048,2.7q.1-.306.943-2.7l.7-1.963h1Z" transform="translate(-1366.696 -1023.49)" fill="#515353"/>
    <path id="Path_156" data-name="Path 156" d="M1430.708,1032.419h-4.431V1024.5h4.372v.835h-3.417v2.715h3.017v.835h-3.017v2.7h3.476Z" transform="translate(-1364.321 -1023.981)" fill="#515353"/>
    <path id="Path_157" data-name="Path 157" d="M1438.661,1031.949h-.931v-3.855q0-1.352-1.144-1.352a1.323,1.323,0,0,0-1.013.512,2.111,2.111,0,0,0-.448,1.439v3.256h-.931v-3.855q0-1.352-1.144-1.352a1.326,1.326,0,0,0-1.013.512,2.111,2.111,0,0,0-.448,1.439v3.256h-.919v-5.959h.919v.964a1.725,1.725,0,0,1,1.685-1.047,1.626,1.626,0,0,1,1.745,1.164,1.969,1.969,0,0,1,.724-.864,1.887,1.887,0,0,1,1.044-.3,1.781,1.781,0,0,1,1.4.529,2.055,2.055,0,0,1,.472,1.421Z" transform="translate(-1362.857 -1023.511)" fill="#515353"/>
    <path id="Path_158" data-name="Path 158" d="M1438.82,1034.267h-.92v-8.273h.92v.906a2.042,2.042,0,0,1,1.851-1,2.238,2.238,0,0,1,1.867.863,3.387,3.387,0,0,1,.678,2.157,3.456,3.456,0,0,1-.713,2.251,2.283,2.283,0,0,1-1.856.875,2.055,2.055,0,0,1-1.827-1Zm0-5.288a2.313,2.313,0,0,0,.531,1.68,1.615,1.615,0,0,0,1.177.541,1.53,1.53,0,0,0,1.239-.593,2.615,2.615,0,0,0,.483-1.687,2.478,2.478,0,0,0-.471-1.575,1.455,1.455,0,0,0-1.191-.6,1.7,1.7,0,0,0-1.244.523A2.349,2.349,0,0,0,1438.82,1028.979Z" transform="translate(-1360.447 -1023.513)" fill="#515353"/>
    <path id="Path_159" data-name="Path 159" d="M1448.256,1028.988a3.233,3.233,0,0,1-.753,2.2,2.6,2.6,0,0,1-2.075.869,2.551,2.551,0,0,1-2.033-.864,3.211,3.211,0,0,1-.748-2.18,3.253,3.253,0,0,1,.772-2.215,2.536,2.536,0,0,1,2.009-.888,2.591,2.591,0,0,1,2.092.871A3.3,3.3,0,0,1,1448.256,1028.988Zm-4.643.011a2.532,2.532,0,0,0,.495,1.581,1.572,1.572,0,0,0,1.32.652,1.624,1.624,0,0,0,1.379-.64,2.567,2.567,0,0,0,.483-1.593,2.729,2.729,0,0,0-.459-1.627,1.611,1.611,0,0,0-1.4-.641,1.563,1.563,0,0,0-1.332.659A2.641,2.641,0,0,0,1443.614,1029Z" transform="translate(-1358.865 -1023.511)" fill="#515353"/>
    <path id="Path_160" data-name="Path 160" d="M1450.438,1031.929h-1.144l-1.839-5.959h.991l.577,1.975q.306,1.034.513,1.8c.136.513.225.856.264,1.028l.059.247q.178-.752.861-3.091l.565-1.963h.991l.577,1.963.884,3.1q.164-.694.907-3.1l.6-1.963h.955l-1.885,5.959h-1.144l-.565-1.951-.825-2.973q-.176.752-.8,2.973Z" transform="translate(-1357.262 -1023.49)" fill="#515353"/>
    <path id="Path_161" data-name="Path 161" d="M1459.33,1031.641a3.625,3.625,0,0,1-1.7.364,2.85,2.85,0,0,1-3.017-3.02,3.231,3.231,0,0,1,.743-2.245,2.455,2.455,0,0,1,1.909-.823,2.306,2.306,0,0,1,1.78.717,2.892,2.892,0,0,1,.659,2.033,3.754,3.754,0,0,1-.059.693h-4.077a1.983,1.983,0,0,0,.672,1.351,2.247,2.247,0,0,0,1.484.471,2.794,2.794,0,0,0,1.6-.412Zm-2.063-4.913a1.542,1.542,0,0,0-1.144.483,2.187,2.187,0,0,0-.553,1.375h3.205v-.235a1.689,1.689,0,0,0-.4-1.181A1.427,1.427,0,0,0,1457.267,1026.728Z" transform="translate(-1354.875 -1023.508)" fill="#515353"/>
    <path id="Path_162" data-name="Path 162" d="M1460.417,1031.943h-.932l.012-5.959h.92v1.045a1.65,1.65,0,0,1,1.625-1.1,1.759,1.759,0,0,1,.331.024v.916a1.606,1.606,0,0,0-.507-.083,1.233,1.233,0,0,0-1.037.576,2.223,2.223,0,0,0-.412,1.328Z" transform="translate(-1353.252 -1023.505)" fill="#515353"/>
    <path id="Path_163" data-name="Path 163" d="M1467.467,1032.419h-2.9V1024.5h2.392a3.148,3.148,0,0,1,1.963.564,1.811,1.811,0,0,1,.736,1.516,1.889,1.889,0,0,1-.383,1.181,1.647,1.647,0,0,1-.984.629,1.934,1.934,0,0,1,1.125.593,1.833,1.833,0,0,1,.465,1.321,1.987,1.987,0,0,1-.631,1.533A2.534,2.534,0,0,1,1467.467,1032.419Zm-.507-7.111h-1.437v2.7h1.272a2.113,2.113,0,0,0,1.415-.4,1.308,1.308,0,0,0,.46-1.035,1.135,1.135,0,0,0-.431-.911A1.961,1.961,0,0,0,1466.96,1025.309Zm.188,3.515h-1.625v2.786h1.8a1.745,1.745,0,0,0,1.161-.347,1.267,1.267,0,0,0,.407-1.017,1.283,1.283,0,0,0-.455-1.045A1.97,1.97,0,0,0,1467.148,1028.823Z" transform="translate(-1351.557 -1023.981)" fill="#515353"/>
    <path id="Path_164" data-name="Path 164" d="M1474.241,1031.929h-.931v-1.035a1.835,1.835,0,0,1-1.875,1.105,1.862,1.862,0,0,1-1.4-.524,1.919,1.919,0,0,1-.513-1.4v-4.1h.932v3.867a1.362,1.362,0,0,0,.329.987,1.186,1.186,0,0,0,.9.341,1.477,1.477,0,0,0,1.184-.517,2.083,2.083,0,0,0,.443-1.4v-3.279h.931Z" transform="translate(-1349.905 -1023.49)" fill="#515353"/>
    <path id="Path_165" data-name="Path 165" d="M1478,1030.357a1.493,1.493,0,0,1-.536,1.211,2.225,2.225,0,0,1-1.467.447,3.663,3.663,0,0,1-1.756-.365v-.9a3.258,3.258,0,0,0,1.7.469,1.367,1.367,0,0,0,.824-.211.681.681,0,0,0,.284-.576.779.779,0,0,0-.313-.659,4.921,4.921,0,0,0-1.043-.528,3.241,3.241,0,0,1-1.079-.647,1.381,1.381,0,0,1-.406-1.047,1.424,1.424,0,0,1,.56-1.18,2.185,2.185,0,0,1,1.4-.441,3.645,3.645,0,0,1,1.5.259v.881a2.846,2.846,0,0,0-1.484-.329,1.181,1.181,0,0,0-.749.217.683.683,0,0,0-.277.559.792.792,0,0,0,.083.369.888.888,0,0,0,.324.312,3.354,3.354,0,0,0,.355.205q.112.054.488.217a3.931,3.931,0,0,1,1.179.693A1.349,1.349,0,0,1,1478,1030.357Z" transform="translate(-1348.346 -1023.505)" fill="#515353"/>
    <path id="Path_166" data-name="Path 166" d="M1478.96,1024.286a.59.59,0,0,1,.177.436.566.566,0,0,1-.177.428.6.6,0,0,1-.436.171.583.583,0,0,1-.6-.6.6.6,0,0,1,.171-.436.574.574,0,0,1,.429-.176A.593.593,0,0,1,1478.96,1024.286Zm.036,8.263h-.931v-5.959H1479Z" transform="translate(-1347.106 -1024.11)" fill="#515353"/>
    <path id="Path_167" data-name="Path 167" d="M1484.767,1031.949h-.931v-3.855a1.388,1.388,0,0,0-.336-1.005,1.168,1.168,0,0,0-.877-.346,1.543,1.543,0,0,0-1.161.517,2,2,0,0,0-.489,1.433v3.256h-.919v-5.959h.919v1.035a1.909,1.909,0,0,1,1.885-1.117,1.86,1.86,0,0,1,1.391.529,1.923,1.923,0,0,1,.519,1.411Z" transform="translate(-1346.396 -1023.511)" fill="#515353"/>
    <path id="Path_168" data-name="Path 168" d="M1489.275,1031.641a3.621,3.621,0,0,1-1.7.364,2.85,2.85,0,0,1-3.016-3.02,3.224,3.224,0,0,1,.743-2.245,2.455,2.455,0,0,1,1.909-.823,2.3,2.3,0,0,1,1.779.717,2.893,2.893,0,0,1,.66,2.033,3.874,3.874,0,0,1-.059.693h-4.078a1.989,1.989,0,0,0,.671,1.351,2.25,2.25,0,0,0,1.485.471,2.786,2.786,0,0,0,1.6-.412Zm-2.061-4.913a1.54,1.54,0,0,0-1.144.483,2.182,2.182,0,0,0-.553,1.375h3.205v-.235a1.683,1.683,0,0,0-.4-1.181A1.421,1.421,0,0,0,1487.214,1026.728Z" transform="translate(-1344.893 -1023.508)" fill="#515353"/>
    <path id="Path_169" data-name="Path 169" d="M1493.086,1030.357a1.493,1.493,0,0,1-.536,1.211,2.231,2.231,0,0,1-1.468.447,3.661,3.661,0,0,1-1.756-.365v-.9a3.26,3.26,0,0,0,1.7.469,1.373,1.373,0,0,0,.825-.211.683.683,0,0,0,.283-.576.778.778,0,0,0-.312-.659,4.933,4.933,0,0,0-1.044-.528,3.22,3.22,0,0,1-1.077-.647,1.377,1.377,0,0,1-.408-1.047,1.424,1.424,0,0,1,.56-1.18,2.185,2.185,0,0,1,1.4-.441,3.645,3.645,0,0,1,1.5.259v.881a2.846,2.846,0,0,0-1.484-.329,1.181,1.181,0,0,0-.749.217.682.682,0,0,0-.276.559.772.772,0,0,0,.083.369.865.865,0,0,0,.324.312,3.381,3.381,0,0,0,.354.205c.075.036.237.108.489.217a3.921,3.921,0,0,1,1.177.693A1.345,1.345,0,0,1,1493.086,1030.357Z" transform="translate(-1343.317 -1023.505)" fill="#515353"/>
    <path id="Path_170" data-name="Path 170" d="M1496.727,1030.357a1.493,1.493,0,0,1-.536,1.211,2.225,2.225,0,0,1-1.467.447,3.655,3.655,0,0,1-1.756-.365v-.9a3.258,3.258,0,0,0,1.7.469,1.369,1.369,0,0,0,.824-.211.679.679,0,0,0,.283-.576.778.778,0,0,0-.312-.659,4.92,4.92,0,0,0-1.042-.528,3.221,3.221,0,0,1-1.079-.647,1.38,1.38,0,0,1-.407-1.047,1.423,1.423,0,0,1,.56-1.18,2.183,2.183,0,0,1,1.4-.441,3.647,3.647,0,0,1,1.5.259v.881a2.848,2.848,0,0,0-1.485-.329,1.174,1.174,0,0,0-.748.217.683.683,0,0,0-.277.559.789.789,0,0,0,.083.369.865.865,0,0,0,.324.312,3.514,3.514,0,0,0,.354.205c.075.036.239.108.489.217a3.931,3.931,0,0,1,1.179.693A1.349,1.349,0,0,1,1496.727,1030.357Z" transform="translate(-1342.103 -1023.505)" fill="#515353"/>
  </g>
  <g id="Group_67" data-name="Group 67" transform="translate(248.441 15.766)">
    <path id="Path_171" data-name="Path 171" d="M1504.342,1006.255h-1.361v-4.623h-1.6v-1.205h4.572v1.205h-1.613Zm4.661,0-1.337-4.276h-.057l.032.9v3.379h-1.321v-5.828h2l1.3,4.123,1.377-4.123h1.945v5.828h-1.361v-3.3l.032-.979h-.05l-1.409,4.276Z" transform="translate(-1501.383 -1000.427)" fill="#00457a"/>
  </g>
</svg>
        </div>
    )
}

export default Logo;