import axios from "axios";
export function menuAPI(config, callback, errorcallback) {
  const URL = process.env.REACT_APP_BASE_URL + "getPage16Block1";
  axios
    .get(URL, config)
    .then((res) => {
      //do something
      //console.log("Menu api working");
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        // console.log("Menu api not working");
        errorcallback(err);
      }
    });
}
export function aboutMenuAPI(config, callback, errorcallback) {
  const URL = process.env.REACT_APP_BASE_URL + "getPage17Block1";
  axios
    .get(URL, config)
    .then((res) => {
      //do something
      //console.log("Menu api working");
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        //console.log("Menu api not working");
        errorcallback(err);
      }
    });
}

export function futuristicMenuAPI(config, callback, errorcallback) {
  const URL = process.env.REACT_APP_BASE_URL + "getPage19Block1";
  axios
    .get(URL, config)
    .then((res) => {
      //do something
      //console.log("Menu api working");
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        //console.log("Menu api not working");
        errorcallback(err);
      }
    });
}
